<template>
  <v-container fluid class="pa-0">
    <v-img
      :src="titles[currentRouteName][bartype].image"
      gradient="to top, rgba(0,0,0, .50), rgba(0,0,0, .50)"
      max-height="500"
      position="50% 20%"
    >
        <v-row class="px-5">
        <v-col cols="12" align="center" justify="center">
          <h2 class="med-text white--text mb-3 px-8">
              {{titles[currentRouteName][bartype].text}}
          </h2>
        </v-col>
      </v-row>
    </v-img>
  </v-container>
</template>
<script>
export default {
props: {
      bartype: String
},
data: () => ({
      loaded_title: "",
      loaded_subtitle: "",
      titles: {
        Marriage: {
          pain: {
              text: 'Premium Quality and Branding',
              image: '/static/asian-couple-argument.jpg'
          },
          happy: {
              text: 'Happy Premium Quality and Branding',
              image: '/static/asian-couple-cooking-in-the-kitchen.jpg'
          }
        },
      }
}),
  components: {},
  computed: {
    currentRouteName() {
      console.log(this.$route.name);
      return this.$route.name;
    },
  },
};
</script>
<style>
.med-text {
  font-size: 2.5em;
}
.medtwo-text {
  font-size: 1.5em;
}
</style>
